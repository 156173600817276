@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,400italic,700,600,700italic");
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #515974;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Roboto', sans-serif !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

button, select, span, p, label, input, textarea {
  font-size: 14px !important;
}

/* label{
  color:#c9d3df !important;
} */

/* label span:last-child {
  color: #515974 !important
} */
.ansRadio span:last-child {
  color: #172a3a !important
}

.disabledRadioBtn .Mui-disabled{
  color: #172a3a !important;
}
.disabledRadioBtn .MuiIconButton-label{
  color: #a2afb2 !important;
}
.intl-tel-input input[type=tel] {
  min-height: 30px;
  width: 100%;
  color:#536c70;
}

.intl-tel-input {
  width: 100%;
}

.intl-tel-input input[type=tel]::placeholder {
  color: #c9d3df !important;
}
.intl-tel-input input[type=tel]:-ms-input-placeholder { /* IE 10+ */
  color: #c9d3df !important;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea:disabled, input:disabled {
  background-color: #ECEBE5;
  color: #555;
}

.selectstyle .MuiInputBase-root {
  border-radius: 4px;
  height: 55px;
  background: #f4f5f5;
}

.selectstyle.disabled .MuiInputBase-root {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.selectstyle .MuiInputBase-input .MuiTypography-root {
  white-space: nowrap !important;
}

.selectstyleCustomProto .MuiInputBase-root {
  background-color: rgba(243, 244, 245, 1);
  border-radius: 4px;
}

.intl-tel-input {
  margin-top: 2px;
}

.MuiPaper-elevation1 {
  z-index: 999 !important;
}
/* .MuiFormLabel-root.Mui-error{
color:#515974 !important
}
.MuiFilledInput-underline.Mui-error:after {
border-bottom: 1px solid rgba(0, 0, 0, 0.42)!important
} */

@media (max-width: 695.95px) {
  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
    max-width: calc(100%) !important;
    margin: 0px !important;
  }
}

@media (max-width:720px) {
  .MuiDialog-paperScrollBody {
    display: block !important;
    max-width: inherit !important;
    margin: 15px !important;
  }
}

@media (max-width:960px) {
  .iti-mobile .intl-tel-input.iti-container {
    top: 269px !important;
    bottom: 49px !important;
    left: 30px !important;
    right: 25px !important;
    position: absolute !important;
    width: 30%;
  }
}

@media (max-width:600px) {
  .iti-mobile .intl-tel-input.iti-container {
    top: 339px !important;
    bottom: 49px !important;
    left: 30px !important;
    right: 25px !important;
    position: absolute !important;
    width: 50%;
  }
}

@media print {
  body * {
    visibility: visible;
  }
  #print-graph, #print-graph * {
    visibility: visible;
  }
  .graphPrint div div {
    overflow: visible !important;
  }
  #print-graph .pad_35, #print-graph .header {
    padding: 15px 0 0 45px !important;
    max-width: 750px !important;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 1;
  }
  #printFooter {
    display: none !important;
  }
  /* logBook Print Css */
  @-moz-document url-prefix() {
    .graphTable {
      display: block !important;
    }
    .borderTable, .borderTable * {
      visibility: visible !important;
      position: static !important;
    }
  }
 
}

.togleBtn .MuiToggleButton-root.Mui-selected {
  background: transparent !important;
  border: 0 !important;
  color: rgba(34, 98, 191, 1);
}

.MuiToggleButton-root {
  border-radius: 8px;
}

.labelstyle {
  font-weight: 400 !important;
  color: #4b7be3 !important;
}

.PrivateValueLabel-label {
  color: white !important;
}

.MuiSlider-thumb {
  background: #fff !important;
  border: 2px solid !important;
}

/* .MuiSlider-valueLabel span{
  background: #ffffff !important;
} */

.MuiInput-underline.Mui-disabled:before {
  border-bottom: 1.5px solid rgba(75, 123, 227, 1) !important;
  border-bottom-style: solid !important;
}

.autoSgstListWrap {
  white-space: pre-wrap !important;
}

.firstLoginCheck {
  align-items: flex-start !important;
}

.firstLoginCheck .MuiButtonBase-root {
  padding: 0px 9px !important;
  /* padding-left: 9px !important; */
}

.toolArrowCustom .MuiTooltip-arrow {
  display: none;
}

.medTool .MuiTooltip-popperArrow[x-placement*="bottom"] ::before, .medTool .MuiTooltip-popperArrow[x-placement*="bottom"] ::after {
  position: absolute;
  content: "";
  top: -21px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #dadde9;
  z-index: 300;
  transform: rotate(180deg);
}

.medTool .MuiTooltip-popperArrow[x-placement*="bottom"] ::after {
  top: -19px;
  border-top-color: #ffffff;
  z-index: 300;
}

.medTool .MuiTooltip-popperArrow[x-placement*="top"] ::before, .medTool .MuiTooltip-popperArrow[x-placement*="top"] ::after {
  position: absolute;
  content: "";
  bottom: -21px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #dadde9;
  z-index: 300;
  transform: rotate(0deg);
}
.inchesCmsField input{
  padding: 8px 10px 12px !important;
}
.medTool .MuiTooltip-popperArrow[x-placement*="top"] ::after {
  bottom: -19px;
  border-top-color: #ffffff;
  z-index: 300;
}

.medTool .MuiGrid-container ::before, .medTool .MuiGrid-container ::after {
  display: none;
}

.toolArrowCustom .MuiCircularProgress-root::before, .toolArrowCustom .MuiCircularProgress-root::after {
  display: none;
}

.react-autosuggest__input {
  width: 100% !important;
  margin-bottom: 15px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  padding: 4px 6px;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.successDialogWrap{
  z-index: 10004 !important;
}
/* .MuiPaper-elevation4{
  box-shadow: none;
} */

/* graph label css */

.highcharts-plot-line-label {
  top: -20px !important;
}

.graphTooltip {
  background-color: rgba(180, 232, 0, 1);
  border: none;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  margin-left: -10px;
}

.graphTooltip:after {
  position: absolute;
  content: "";
  top: 25px;
  left: 50%;
  margin-left: -11px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: rgba(180, 232, 0, 1);
  z-index: 300;
}

.summarygraphTooltip {
  background-color: rgba(180, 232, 0, 1);
  border: none;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  margin-left: -10px;
  font-size: 12px !important;
}

.summarygraphTooltip:after {
  position: absolute;
  content: "";
  top: 42px;
  left: 48%;
  margin-left: -11px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top-color: rgba(180, 232, 0, 1);
  z-index: 300;
  font-size: 12px !important;
}

.customGraphTable .highcharts-container {
  z-index: 101 !important;
}

.customGraphTable div {
  box-shadow: none !important;
}

.customGraphTable div div {
  overflow: visible !important;
}

.highcharts-container svg.highcharts-root {
  overflow: visible !important;
}

.customGraphTable table tr:last-child td:last-child {
  overflow: hidden !important;
}
.customGraphTable.graphTableRowCount-1 table tr:last-child td:last-child,.customGraphTable.graphTableRowCount-1 table tr:last-child td {
  overflow: visible !important;
  border-bottom: none;
}
.MuiButton-root {
  min-width: 0 !important;
}

.searchResultList {
  background-color: #ffffff !important;
  border-bottom: 1px solid rgba(243, 244, 245, 1) !important;
  box-shadow: none;
  border-radius: 0;
}

.react-datepicker-wrapper, .react-datepicker__input-container, .MuiFormControl-root {
  width: 100% !important;
}

.tabsContainer .MuiTabs-indicator {
  background-color: rgba(0,0,0,0) !important;
}

.MuiTablePagination-selectIcon {
  top: 6px !important;
}

.navBarMenu .MuiMenu-paper {
  top: 8% !important;
  left: 19% !important;
  border-radius: 0 !important;
}

.simpleMenu li {
  border-bottom: 1.6px solid rgb(248, 248, 248);
  padding: 14px 16px !important;
}

/* .simpleMenu li:last-child{
  border-bottom:1.6px solid #4b7be3;
} */

.navBarMenu ul {
  padding: 0;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.displayMedicationToolTipArrow .MuiTooltip-arrow {
  color: #fff !important;
}

.hidetableBody tbody, .hidetableBody .MuiTableRow-footer {
  display: none;
}

.hidetableBody {
  box-shadow: none !important;
  max-height: 40px;
}

.selectstyle .MuiInputBase-root {
  cursor: pointer;
}

.MuiMenuItem-root {
  white-space: pre-wrap !important;
}

.disableProfileLabelColor label {
  color: #515974 !important;
}

.fieldstyle .MuiPaper-root {
  z-index: 10001 !important;
}

.adminNavSelect .MuiInputBase-input div:first-child {
  flex-wrap: inherit !important;
}

#pagination-menu {
  z-index: 10001 !important;
}

/* Multidates css for dialog */

.custumFreqencyBtn .MuiToggleButton-label {
  min-height: 94px;
}

.custumFreqencyBtn.MuiToggleButton-root:last-child {
  margin-right: 0;
}

.custumFreqencyBtn.Mui-disabled{
  color:#536c70 !important;
}
.custumFreqencyBtn.Mui-disabled.Mui-selected{
  color: #172a3a !important;
}
/* multidates css dialog end */

.medicationDays .MuiIconButton-colorSecondary.Mui-checked.Mui-disabled span:last-child{
  color: rgb(187, 225, 27) !important;
}
.mainRoot {
  z-index: 10004 !important;
}

.menu .MuiPopover-paper {
  width: 175px;
}

.MuiFilledInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

.MuiInputBase-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.disabledFeild input:disabled {
  background-color: #e0e0e0 !important;
  color:rgba(0, 0, 0, 0.38) !important;
}

.formatedOptions span {
  font-size: 9px !important;
  padding: 3px 7px;
  border-radius: 8px;
}

.formatedOptions .onLabel {
  background: #d7d7d7;
}

.formatedOptions .onSite {
  background: #eef2f3;
}

.labelWrap {
  max-width: 160px;
  font-size: 14px !important;
}

.formatedOptions {
  display: block;
}

.labelBody {
  /* min-width: 215px; */
  color: #172a3a;
  word-break: break-all;
  float: left;
}

/* .labelBodyPrescription {
  min-width: 130px;
} */
/* 
.labelWrapPrescription {
  max-width: 110px;
} */

.customLabel {
  float: right;
  margin-left: 10px;
  color: #172a3a;
  text-transform: uppercase
}

.SingleValue .customLabel {
  display: none;
}

.SingleValue .labelWrapPrescription {
  max-width: 155px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width:1150px) {
  .checkBoxGroup div:first-child {
    width: 100%;
    display: flex;
    flex-wrap: wrap
  }
  .checkBoxGroup .MuiFormControlLabel-root {
    flex-grow: 0;
    max-width: 33.333333%;
    width: 100%;
    position: relative;
    flex-basis: auto;
    min-height: 1px;
    margin-right: 0;
  }
}

@media only screen and (max-width:1150px) {
  .checkBoxGroup div:first-child {
    width: 100%;
    display: flex;
    flex-wrap: wrap
  }
  .checkBoxGroup .MuiFormControlLabel-root {
    flex-grow: 0;
    max-width: 100%;
    width: 100%;
    position: relative;
    flex-basis: auto;
    min-height: 1px;
    margin-right: 0;
  }
}

.dashboardPatientFilter .MuiToggleButton-root.Mui-selected {
  background: #e1e5e6 !important;
  border: 2px solid #dadfe0 !important;
}

.dashboardPatientFilter .MuiToggleButton-root {
  height: inherit;
  line-height: 1;
  background-color: #f3f4f5;
}

.dashboardPatientFilter .MuiToggleButton-root {
  padding: 5px 11px 5px 12px;
}
.dashboardPatientFilter .MuiToggleButton-root:not(:first-child){
  border-left: 1px solid #dadfe0;
}
.dashboardPatientFilter .MuiToggleButton-label {
  font-size: 16px !important;
  font-weight: 400;
}
.dashboardPatientFilter .MuiToggleButton-root.Mui-selected .MuiToggleButton-label .customLabelSelectedWeight{
  font-weight: 600 !important;
}
.actionDashboard{
  left: -35px !important;
}
.tableHeadSize {
  font-size: 14px !important;
}

.popOverListStyle .MuiList-padding {
  padding: 0;
  border-bottom: 2px solid #2162bf;
}

.popOverListStyle .MuiList-padding li {
  padding: 10px 6px !important;
  margin: 0 10px;
}
.noPatientBtn span{
  font-size: 16px !important;
}
.MuiTableSortLabel-active .MuiTableSortLabel-icon{
  margin-left: 0;
}
.prescriptionMedicationActionPopup{
  left:-60px !important;
  top: 10px !important;
  z-index:999;
}
.aereportTooltip .MuiTooltip-arrow{
  font-size: 8px !important;
}





/*  Please keep this code  always at the bottom of the file. This is added to fix IE issues */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .dispFlexEndIE {
    align-items: flex-end !important;
    /* Enter your style code */
  }
  .selectstyle .MuiInputBase-input .MuiTypography-root {
    overflow: visible !important;
  }
  .firstDiagnosis .MuiInputBase-input .MuiTypography-root{
    overflow: hidden !important;
  }
  .dispBaselineIE{
    align-items: baseline !important;
  }
  .selectstyleIE .IEplceHolderCenter{
    padding: 0 12px 10px !important;
  }
  input::-ms-clear{
    display: none !important;
  }
  .deathResponse .MuiIconButton-label input[type=radio]{
    z-index: 0;
  }
}
@supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
/* @supports (backdrop-filter: none) { */
.dialog {
  /* opacity: 0.88;
   filter: alpha(opacity=88); 
   filter: progid:DXImageTransform.Microsoft.Alpha(opacity=88);
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=88)"; */
  -webkit-backdrop-filter: blur(4px) !important;
  backdrop-filter: blur(4px);
  background-color: rgba(74, 74, 74, 0.03);
}}
 .MuiBackdrop-root {
background-color: rgba(0, 0, 0, 0.1) !important;
  }
.css-14uuagi { 
    font-size: 0px !important;
}
